import type { FC } from 'react'

interface Props {
  config: any
}

const Footer: FC<Props> = ({ config }) => {
  const { urls } = config
  const { supportUrl } = urls
  return (
    <footer className="footer">
      <div className="footer__content container">
        <div className="footer__menu">
          <div className="footer__item">
            <b>CONTACT INFORMATION</b>
            <a href="#">
              <img src="/img/home/footer-logo.svg" alt="" />
            </a>
            <p>265 S. Federal Hwy</p>
            <p>#344</p>
            <p>Deerfield Beach, FL 33441</p>
          </div>
          <div className="footer__item">
            <b>PRODUCT LINKS</b>
            <ul>
              <a href={supportUrl}>
                <li>Support</li>
              </a>
              <a href={supportUrl}>
                <li>Contact</li>
              </a>
              <a href="/faqs">
                <li>FAQ&apos;s</li>
              </a>
              <a href="/how-to-uninstall">
                <li>Uninstall Instructions</li>
              </a>
              <a href="/moneyback">
                <li>Money Back Guarantee</li>
              </a>
            </ul>
          </div>
          <div className="footer__item">
            <b>LEGAL</b>
            <ul>
              <a href="/legal/values">
                <li>Software Values & Principles</li>
              </a>
              <a href="/legal/refund-policy">
                <li>Refund Policy</li>
              </a>
              <a href="/legal/privacy-policy">
                <li>Privacy Policy</li>
              </a>
              <a href="/legal/cookie-policy">
                <li>Cookie Policy</li>
              </a>
              <a href="/legal/terms-of-service">
                <li>Terms of Use</li>
              </a>
              <a href="/legal/eula">
                <li>End User License Agreement</li>
              </a>
            </ul>
          </div>
        </div>
        <div className="footer__copyright">
          <p>
            Copyright &copy; 2018-{new Date().getFullYear()}. SB Software Group
            LLC. All Rights Reserved.
          </p>
          <p>
            PC Operating System(s): (32/64 Bit) Windows 11 / Windows 10 /
            Windows 8 / Windows 7
          </p>
          <p>
            TotalSystemCare service saves you time by automatically optimizing
            your PC for you.
          </p>
          <p>
            Every used computer state depends on a various set of factors
            outside of TotalSystemCare&apos;s control. Residing security
            applications. Installed hardware and many other circimstances may
            impact the effectiveness and problem solving ability of our
            applications on your system. Access to TotalSystemCare full
            functionality requires an active subscription. Service and
            applications functionality including updates, upgrades and unimited
            access to service representatives is included.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
